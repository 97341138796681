var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { "max-width": "1000px", margin: "auto" },
    },
    [
      _c("h2", { staticClass: "text-center" }, [_vm._v("BÁO CÁO QUẢN TRỊ")]),
      _vm._v(" "),
      _c("div", { staticClass: "select-box" }, [
        _c(
          "div",
          { staticStyle: { width: "100%", "text-align": "center" } },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Chọn khoảng thời gian:"),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "filter-item",
              attrs: {
                type: "daterange",
                "range-separator": "To",
                format: "dd/MM/yyyy",
                "value-format": "yyyy-MM-dd",
                "start-placeholder": "Ngày bắt đầu",
                "end-placeholder": "Ngày kết thúc",
                clearable: false,
              },
              on: { change: _vm.getData },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.reportData
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "50px" }, attrs: { gutter: 42 } },
            [
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Số lượng truy cập"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            _vm.reportData.totalUserByDate
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", [_vm._v("Tổng số người bán trên hệ thống")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(_vm.reportData.totalStore)
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", [_vm._v(" Số lượng người bán mới")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            _vm.reportData.totalStoreByDate
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", [_vm._v(" Tổng số lượng sản phẩm")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            _vm.reportData.totalProduct
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", [_vm._v(" Tổng số lượng sản phẩm mới")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            _vm.reportData.totalNewProduct
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", [_vm._v(" Tổng số đơn hàng")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(_vm.reportData.totalOrder)
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", [_vm._v(" Tổng số đơn hàng thành công")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            _vm.reportData.totalComplete
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", [_vm._v(" Tổng số đơn hàng không thành công")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(_vm.reportData.totalCancel)
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, xs: 24, offset: 0 } }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", [_vm._v(" Tổng giá trị giao dịch")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("toThousandFilter")(_vm.reportData.totalMoney)
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }