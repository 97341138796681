"use strict";

var _interopRequireWildcard = require("C:/Users/OS/Documents/sources/050-Report/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/Users/OS/Documents/sources/050-Report/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("C:\\Users\\OS\\Documents\\sources\\050-Report\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("C:\\Users\\OS\\Documents\\sources\\050-Report\\node_modules\\core-js\\modules\\es6.promise.js");
require("C:\\Users\\OS\\Documents\\sources\\050-Report\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("C:\\Users\\OS\\Documents\\sources\\050-Report\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var VueGoogleMaps = _interopRequireWildcard(require("vue2-google-maps"));
var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));
var _locale = _interopRequireDefault(require("element-ui/lib/locale"));
require("./icons");
require("./permission");
require("./utils/error-log");
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var filters = _interopRequireWildcard(require("./filters"));
var _fontawesomeSvgCore = require("@fortawesome/fontawesome-svg-core");
var _freeSolidSvgIcons = require("@fortawesome/free-solid-svg-icons");
var _vueFontawesome = require("@fortawesome/vue-fontawesome");
require("@/plugins/input-mask");
var _url = require("./utils/url");
var _settings = _interopRequireDefault(require("./settings"));
// a modern alternative to CSS resets

// global css

//google map

_vue.default.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_KEY_GOOGLE_MAP,
    libraries: "places",
    region: "VI",
    language: "vi"
  }
});
//

// icon
// permission control
// error log

var VueInputMask = require("vue-inputmask").default;
_vue.default.use(VueInputMask);

// global filters

_vue.default.prototype.$cloudFrontUrl = process.env.VUE_APP_CLOUD_FRONT_URL;
_fontawesomeSvgCore.library.add(_freeSolidSvgIcons.faMotorcycle, _freeSolidSvgIcons.faTruck, _freeSolidSvgIcons.faUserTag, _freeSolidSvgIcons.faUtensils, _freeSolidSvgIcons.faPeopleCarry, _freeSolidSvgIcons.faUserSecret, _freeSolidSvgIcons.faDollarSign, _freeSolidSvgIcons.faTicketAlt, _freeSolidSvgIcons.faUserNurse, _freeSolidSvgIcons.faProcedures, _freeSolidSvgIcons.faAssistiveListeningSystems);
_vue.default.prototype.$url = _url.$url;
_vue.default.prototype.$version = _settings.default.version;
_vue.default.component("font-awesome-icon", _vueFontawesome.FontAwesomeIcon);
_locale.default.use(_vi.default);
_vue.default.use(_elementUi.default, {
  size: "small" // set element-ui default size
});

_vue.default.use(_vueClipboard.default);

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});