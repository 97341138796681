"use strict";

var _interopRequireDefault = require("C:/Users/OS/Documents/sources/050-Report/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.remove = remove;
exports.sort = sort;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/area",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/area",
    method: "post",
    data: data
  });
}
function update(areaId, data) {
  return (0, _request.default)({
    url: "/area/".concat(areaId, "/update"),
    method: "post",
    data: data
  });
}
function sort(data) {
  return (0, _request.default)({
    url: "/area/sort",
    method: "patch",
    data: data
  });
}
function remove(areaId) {
  return (0, _request.default)({
    url: "/area/".concat(areaId, "/delete"),
    method: "post"
  });
}