"use strict";

var _interopRequireDefault = require("C:/Users/OS/Documents/sources/050-Report/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getReportBCT = getReportBCT;
var _request = _interopRequireDefault(require("@/utils/request"));
function getReportBCT(listQuery) {
  return (0, _request.default)({
    baseURL: process.env.VUE_APP_BASE_PUBLIC_API,
    url: "/report/general",
    method: "get",
    params: listQuery
  });
}