"use strict";

var _interopRequireDefault = require("C:/Users/OS/Documents/sources/050-Report/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var _request = _interopRequireDefault(require("@/utils/request"));
//
//
var _default = exports.default = {
  created: function created() {
    var _this = this;
    (0, _request.default)({
      url: "/password/forgot/confirm",
      method: "post",
      data: {
        token: this.$route.query.token
      }
    }).then(function () {
      _this.$message({
        type: "success",
        message: "Đã gửi mật khẩu tới Email của bạn"
      });
    }).finally(function () {
      setTimeout(function () {
        location.href = "https://google.com";
      }, 3000);
    });
  }
};